<template>
  <div class="ant-hx-table">
    <div class="ant-hx-table-search" v-if="$slots.search">
      <!--搜索条件 -->
      <slot name="search"></slot>

    </div>
    <a-card :bodyStyle="{ 'padding': '0px' }" ref="card">
      <div class="ant-hx-table-toolbar">
        <div class="ant-hx-table-toolbar-container">
          <a-space>
            <div v-if="$slots.toolbarLeft">
              <slot name="toolbarLeft"></slot>
            </div>

            <div>
              <a-space>
                <a-popover overlayClassName="ant-hx-table-column-setting-overlay" placement="bottom" trigger="click"
                  arrow-point-at-center>
                  <template #content>
                    <div class="ant-hx-table-column-setting-list">
                      <a-checkbox-group v-model:value="checkColumns" @change="changeCheckColumns">
                        <div class="ant-hx-table-column-setting-list-item" v-for="column in columns"
                          :key="column.dataIndex">
                          <!-- <NumberOutlined class="ant-hx-table-column-drag-icon" /> -->
                          <a-checkbox :value="column.dataIndex">{{ column.title }}</a-checkbox>
                        </div>
                      </a-checkbox-group>
                    </div>
                  </template>
                  <template #title>
                    <div class="ant-hx-table-column-setting-title">
                      <span>{{ $t('columnTitle') }}</span>
                      <a @click="columnReset">{{ $t('columnReset') }}</a>
                    </div>
                  </template>
                  <a-button>
                    <ControlOutlined :rotate="90" />列展示
                  </a-button>
                </a-popover>

                <a-button @click="reload">
                  <RedoOutlined :rotate="240" />刷新
                </a-button>
              </a-space>
            </div>
            <div v-if="$slots.totalbar" class="ant-hx-table-toolbar-container-right-totalbar">
              <slot name="totalbar"></slot>
            </div>
          </a-space>
        </div>
      </div>
      <slot></slot>
    </a-card>
  </div>
</template>

<script>

import { reactive, toRefs, defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { RedoOutlined, ControlOutlined } from '@ant-design/icons-vue'

// defineComponent 重载函数
export default defineComponent({
  name: 'HxTable',
  components: {
    RedoOutlined,
    ControlOutlined
  },
  props: {
    reload: {
      type: Function || Boolean
    },
    columns: {
      type: Array
    },
    type: Number // 1为私海，2位合作
  },
  emits: ['update:columns'],
  setup (props, { emit }) {
    const currentRoute = useRoute()
    const state = reactive({
      columns: props.columns,
      checkColumns: props.columns.map(column => { return column.dataIndex })
    })
    onMounted(() => {
      if (window.localStorage.getItem(currentRoute.name)) {
        let list = JSON.parse(window.localStorage.getItem(currentRoute.name))
        state.checkColumns = list.map(column => { return column.dataIndex })
        emit('update:columns', list)
      }
      // if (props.type === 1 && window.localStorage.getItem(currentRoute.name)) {
      // } else if (window.localStorage.getItem(currentRoute.name)) {
      //   let list = JSON.parse(window.localStorage.getItem(currentRoute.name))
      //   state.checkColumns = list.map(column => { return column.dataIndex })
      //   emit('update:columns', list)
      // }
    })
    const changeCheckColumns = checkItem => {
      emit('update:columns', state.columns.filter(column => checkItem.includes(column.dataIndex)))
      if (props.type === 1) {
        let list = JSON.stringify(state.columns.filter(column => checkItem.includes(column.dataIndex)))
        // window.localStorage.removeItem(currentRoute.name)
        window.localStorage.setItem(currentRoute.name, list)
      } else {
        let list = JSON.stringify(state.columns.filter(column => checkItem.includes(column.dataIndex)))
        // window.localStorage.removeItem(currentRoute.name)
        window.localStorage.setItem(currentRoute.name, list)
      }
    }
    const columnReset = () => {
      state.checkColumns = state.columns.map(column => { return column.dataIndex })
      emit('update:columns', state.columns)
      if (props.type === 1) {
        window.sessionStorage.setItem(currentRoute.name, JSON.stringify(state.columns))
      } else {
        window.sessionStorage.setItem(currentRoute.name, JSON.stringify(state.columns))
      }
    }
    return {
      ...toRefs(state),
      changeCheckColumns,
      columnReset
    }
  }
})
</script>
<style lang="less" scoped>
@import './index.less';
</style>
